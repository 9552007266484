.portfolioContainer {
  background-color: #ffffff; /* Clean white background */
  padding: 40px 20px;
  border-radius: 16px; /* Soft rounded edges */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
  max-width: 1200px; /* Responsive width */
  margin: 0 auto; /* Centering */
}

.portfolioTitle {
  text-align: center;
  color: #272727;
  font-weight: 500; /* Lighter weight for modern feel */
  text-transform: uppercase;
  margin-bottom: 40px;
  letter-spacing: 1px;
  font-size: 2em; /* Larger, impactful font size */
}

.projectCard {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15); /* Stronger shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }

  .imageContainer {
    width: 100%;
    overflow: hidden;
    border-bottom: 3px solid #e0e0e0;
    max-height: 300px; /* Increase height for a bigger image display */
  }

  .projectImage {
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease;
    object-fit: cover; /* Make sure the image scales well */

    &:hover {
      transform: scale(1.05); /* Slight zoom on hover */
    }
  }
}

.dialogContent {
  position: relative;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center align content */
  max-width: 800px; /* Limit width to avoid full-screen */
  margin: 0 auto; /* Center the dialog */
}

.dialogImages {
  display: flex;
  flex-direction: column; /* Stack images vertically */
  overflow-y: auto; /* Enable vertical scrolling */
  gap: 20px; /* Space between images */
  padding: 10px 0;
  width: 100%; /* Full width */
}

.dialogImage {
  max-width: 100%; /* Allow image to be responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px;
  object-fit: cover; /* Keep images clean and well-positioned */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5em; /* Larger for easy access */
  color: #e74c3c;
  transition: color 0.2s ease;

  &:hover {
    color: #c0392b;
  }
}
